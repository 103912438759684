import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  ViewChild,
} from "@angular/core";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { MatMonthView } from "@angular/material";
import { FormBuilder } from "@angular/forms";
import { MatStepper } from "@angular/material/stepper";
import { SharedServiceService } from "../services/shared-service.service";
import { DataServiceService } from "./../services/data-service.service";
import { ParentComponent } from "../parent/parent.component";
import { ThrowStmt } from "@angular/compiler";
import { AuthWithOAuthService } from "src/app/services/oauth/auth-with-oauth.service";
import { Router } from "@angular/router";

const COLUMNS_SCHEMA = [
  {
    key: "Name",
    type: "text",
    label: "Name",
  },
  {
    key: "Designation",
    type: "text",
    label: "Designation",
  },
  {
    key: "FocusArea",
    type: "text",
    label: "Focus Area",
  },
  {
    key: "BioRemarks",
    type: "text",
    label: "Bio/ Remarks",
  },
  // {
  //   key: "VisitedLab",
  //   type: "checkbox",
  //   label: "If visited Lab before",
  // },
  {
    key: "PhotoName",
    type: "text",
    label: "Photo",
  },
];

@Component({
  selector: "app-bookalab",
  templateUrl: "./bookalab.component.html",
  styleUrls: ["./bookalab.component.css"],
})
export class BookalabComponent extends ParentComponent implements OnInit {
  // Lob = new FormControl();
  // LobList: string[] = ['L&A', 'Retirement', 'GB', 'P&C Personal', 'P&C Commercial'];

  toppings = new FormControl();
  toppingList: string[] = [
    "L&A",
    "Retirement",
    "GB",
    "P&C Personal",
    "P&C Commercial",
  ];

  isLinear = true;
  index = 0;
  enableTimeslots = true;
  enableAddDelegate = false;
  delegateImage = "";
  delegateIndex = "";

  firstFormGroup: FormGroup;
  secondFormGroup: FormGroup;
  @ViewChild("stepper") stepper: MatStepper;

  closeResult = "";
  minDate = new Date();
  formModal: any;
  public selectedGeo: any;
  public selectedTime: any;
  public selectedEndTime: any;
  public selectedLob: any;
  public selectedFile: File = null;
  public imageFile: boolean = false;
  public timeslots: any;
  public endTimeSlots: any;
  public extractedUserName: any;
  public solutionList: any;
  public LobList: any;
  public geographyValidation: any;
  public newEndTime: any;

  dynamicArray = [];
  newDynamic;
  blockedDates = [
    "2023-08-15",
    "2023-08-29",
    "2023-10-02",
    "2023-10-24",
    "2023-11-13",
    "2023-12-25",
  ];
  scriptTagValidator = Validators.pattern(/^(?!.*<\/?[a-z]+>).*$/i);
  // Array for Blocking Public Holidays in any particular upcoming year.You can add dates in the following format "YYYY-MM-DD"

  backdrop = false;
  enableSubmit = true;
  bookingSuccess = false;
  summaryStep = true;
  displayTitle = true;
  showTable = false;
  displayFileButton = false;
  // Deleagate Boolean
  delagateNameBool = false;
  delegateDesignationBool = false;
  delegateFocusAreaBool = false;
  delegatePhotoBool = false;
  displayErrorMsg = false;
  delagateName = "";
  delegateDesignation = "";
  delegateFocusArea = "";
  delegatePhoto = "";

  enableDetailsBtn = true;

  @Output() close = new EventEmitter<string>();
  // @Output() modalOpen = new EventEmitter();

  bookalabvalidation = new FormGroup({
    accountlab: new FormControl("", Validators.required),
    cp: new FormControl("", Validators.required),
    desc: new FormControl("", Validators.required),
    date: new FormControl("", Validators.required),
    stakeholder: new FormControl("", Validators.required),
  });
  constructor(
    private http: HttpClient,
    public _http: DataServiceService,
    private _formBuilder: FormBuilder,
    private router: Router
  ) {
    super(_http);

    this.geographyValidation = ["USA", "GGM"];
    this.selectedGeo = "";

    this.LobList = [
      {
        id: "1",
        name: "L&A",
      },
      {
        id: "2",
        name: "Retirement",
      },
      {
        id: "3",
        name: "GB",
      },
      {
        id: "4",
        name: "P&C Personal",
      },
      {
        id: "5",
        name: "P&C Commercial",
      },
    ];

    this.solutionList = [
      {
        id: "1",
        name: "AI Led Claims Damage Assessment",
      },
      {
        id: "2",
        name: "Targeted Digital Marketing",
      },
      {
        id: "3",
        name: "Cognizant Small Medium Enterprise",
      },
      {
        id: "4",
        name: "Life eApp",
      },
      {
        id: "5",
        name: "Blink Assist",
      },
      {
        id: "6",
        name: "Real-time cognitive contact center analytics",
      },
      {
        id: "7",
        name: "Metaverse",
      },

      {
        id: "8",
        name: "Smart learning using immersive experience",
      },
      {
        id: "9",
        name: "Digital sales & service",
      },
      {
        id: "10",
        name: "Personalized Interactive Videos",
      },
      {
        id: "11",
        name: "Conversational AI",
      },
      {
        id: "12",
        name: "My Wellwisher",
      },
      {
        id: "13",
        name: "Plan Sponsor Onboarding",
      },
      {
        id: "14",
        name: "Retirement Income Advisor",
      },
      {
        id: "15",
        name: "Quote Express",
      },
      {
        id: "16",
        name: "UW  360 Cockpit",
      },
      {
        id: "17",
        name: "Interactive home quote",
      },
      {
        id: "18",
        name: "Connected property",
      },
      {
        id: "19",
        name: "Smart Loss Capture",
      },
      {
        id: "20",
        name: "Virtual collaboration",
      },
      {
        id: "21",
        name: "Field Service Transformation",
      },
      {
        id: "22",
        name: "Streamlining Payment Process",
      },
      {
        id: "23",
        name: "Advanced Fraud Analytics",
      },
      {
        id: "24",
        name: "RealTime Fraud Protection",
      },
    ];

    this.timeslots = [
      "10:00 AM",
      "10:30 AM",
      "11:00 AM",
      "11:30 AM",
      "12:00 PM",
      "12:30 PM",
      "1:00 PM",
      "1:30 PM",
      "2:00 PM",
      "2:30 PM",
      "3:00 PM",
      // '3:30 PM',
      // '4:00 PM',
    ];
    this.endTimeSlots = [];

    this.selectedTime = "";
    this.selectedEndTime = "";
  }
  ngOnInit() {
    // this.disablePastDate();
    // sessionStorage.setItem("userName", "B, Subash Chandar (Cognizant)");
    // sessionStorage.setItem("emailId", "2128666@cognizant.com");
    // sessionStorage.setItem("userId", "2128666");
    var tempData = sessionStorage.getItem("userName").split(",");
    var tempData1 = tempData[1].split(" ");
    console.log(tempData1[1]);
    this.extractedUserName = tempData1[1];

    this.firstFormGroup = this._formBuilder.group({
      radioValidation: ["", Validators.required],
      dateValidation: ["", Validators.required],
      timeValidation: ["", Validators.required],
      endTimeValidation: [""],
    });
    this.secondFormGroup = this._formBuilder.group({
      accountValidation: ["", [Validators.required, this.scriptTagValidator]],
      geographyValidation: ["", Validators.required],
      edpName: ["", Validators.required],
      projectValidation: [
        "",
        [Validators.required, Validators.pattern(/^(?!([0-9])\1+$)\d{1,10}$/)],
      ],
      crmName: ["", Validators.required],
      visitPoc: ["", Validators.required],
      yearsWithCognizant: [],
      accountDetails: ["", [this.scriptTagValidator]],
      comments: ["", [this.scriptTagValidator]],
      LobList: ["", Validators.required],
      solutions: [],
    });
  }

  checkNameValidation(event) {
    this.delagateName = event;
    console.log(this.delagateName);
    const pattern = /^[^<>]+$/;
    const isValid = pattern.test(this.delagateName);
    console.log(isValid);
    if (this.delagateName.length > 0 && isValid) {
      this.delagateNameBool = true;
    } else {
      this.delagateNameBool = false;
      this.enableDetailsBtn = true;
      this.enableAddDelegate = true;
    }
    console.log("checkNameValidation", this.delagateNameBool, isValid);
    if (
      this.delagateNameBool &&
      this.delegateDesignationBool &&
      this.delegateFocusAreaBool
    ) {
      console.log("Enable next btn");
      this.enableDetailsBtn = false;
      this.enableAddDelegate = false;
    }
  }

  printLobValue(event) {
    console.log(event);
  }

  openSolution() {
    console.log("Solution component trigger");
    window.open("/solutions", "_blank");
  }

  checkDesignationValidation(event) {
    this.delegateDesignation = event;
    const pattern = /^[^<>]+$/;
    const isValid = pattern.test(this.delegateDesignation);
    console.log(isValid);
    console.log(this.delegateDesignation);
    if (this.delegateDesignation.length > 0 && isValid) {
      this.delegateDesignationBool = true;
    } else {
      this.delegateDesignationBool = false;
      this.enableDetailsBtn = true;
      this.enableAddDelegate = true;
    }
    console.log(
      "checkDesignationValidation :",
      this.delegateDesignationBool,
      isValid
    );
    if (
      this.delagateNameBool &&
      this.delegateDesignationBool &&
      this.delegateFocusAreaBool
    ) {
      console.log("Enable next btn");
      this.enableDetailsBtn = false;
      this.enableAddDelegate = false;
    }
  }

  checkFocusValidation(event) {
    this.delegateFocusArea = event;
    const pattern = /^[^<>]+$/;
    const isValid = pattern.test(this.delegateFocusArea);
    console.log(isValid);
    console.log(this.delegateFocusArea);
    if (this.delegateFocusArea.length > 0 && isValid) {
      this.delegateFocusAreaBool = true;
    } else {
      this.delegateFocusAreaBool = false;
      this.enableDetailsBtn = true;
      this.enableAddDelegate = true;
    }
    if (
      this.delagateNameBool &&
      this.delegateDesignationBool &&
      this.delegateFocusAreaBool
    ) {
      console.log("Enable next btn");
      this.enableDetailsBtn = false;
      this.enableAddDelegate = false;
    }
    console.log("checkFocusValidation", this.delegateFocusAreaBool, isValid);
  }

  onExcelFileSelected(event) {}

  onFileSelected(event, index) {
    console.log(event + " " + index);
    // if (event.target.files[0].name.length > 0) {
    //   this.delegatePhotoBool = true;
    // } else {
    //   this.delegatePhotoBool = false;
    //   this.enableDetailsBtn = true;
    // }
    // if (
    //   this.delagateNameBool &&
    //   this.delegateDesignationBool &&
    //   this.delegateFocusAreaBool
    // ) {
    //   console.log("Enable next btn");
    //   this.enableDetailsBtn = false;
    // }
    // console.log(this.delegatePhotoBool);

    this.selectedFile = event.target.files[0].name;
    console.log(event.target.files);

    const file = event.target.files[0];

    if (file) {
      const fileName: string = file.name;

      if (this.hasDoubleExtension(fileName)) {
        console.log("File has double extension");
        event.target.value = ""; // Clear the selected file
        return; // Stop further processing
      }

      if (!this.hasValidFileName(fileName)) {
        console.log("Invalid file name");
        event.target.value = ""; // Clear the selected file
        return; // Stop further processing
      }

      // Continue with handling the selected file
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        console.log(reader.result);
        console.log(typeof reader.result);
        this.dynamicArray[index].Photo = reader.result.toString();
        this.dynamicArray[index].PhotoName = event.target.files[0].name;
      };
      console.log(
        this.delagateName +
          " " +
          this.delegateDesignation +
          " " +
          this.delegateFocusArea +
          " " +
          this.delegatePhoto
      );
    }
  }

  hasDoubleExtension(fileName: string): boolean {
    const regex = /\.[^.\\/]+?\.[^.\\/]+$/;
    return regex.test(fileName);
  }

  hasValidFileName(fileName: string): boolean {
    const regex = /^[a-zA-Z0-9\s-_()]+(\.[a-zA-Z0-9\s-_()]+)?$/;
    return regex.test(fileName);
  }
  changeUI() {
    this.bookingSuccess = true;
    this.summaryStep = false;
    this.displayTitle = false;
    this.stepData.formName = "BookaLab";
    console.log(this.stepData);
    // this.http
    //   .post(
    //     "https://hhxa8qsu2m.execute-api.us-east-1.amazonaws.com/dev/testresource",
    //     JSON.stringify(this.stepData)
    //   )
    //   .subscribe((response) => {
    //     console.log("Posted to API :", this.stepData);
    //   });

    this.http
      .post(
        "https://gag6jl5kei.execute-api.us-east-1.amazonaws.com/prod/todigitalfeedback",
        JSON.stringify(this.stepData)
      )
      .subscribe((response) => {
        console.log("Uploaded to DynamoDB :", this.stepData);
      });
  }

  displayDelegateTable() {
    console.log("Delegate table triggered");
    console.log(this.dynamicArray);
    this.showTable = true;
    this.dataSource = this.dynamicArray;
    this.stepData.delegateDetails = this.dynamicArray;
    this.stepData.userName = sessionStorage.getItem("userName");
    this.stepData.empId = sessionStorage.getItem("userId");
    this.stepData.emailId = sessionStorage.getItem("emailId");
    console.log(
      sessionStorage.getItem("userName") +
        " " +
        sessionStorage.getItem("userId") +
        " " +
        sessionStorage.getItem("emailId")
    );
  }

  modifyDelegateData() {
    this.showTable = false;
    this.dataSource = this.dynamicArray;
  }

  addRow() {
    var now = new Date();
    var year = now.getFullYear();
    var month = String(now.getMonth() + 1).padStart(2, "0");
    var day = String(now.getDate()).padStart(2, "0");
    var hour = String(now.getHours()).padStart(2, "0");
    var minute = String(now.getMinutes()).padStart(2, "0");
    var second = String(now.getSeconds()).padStart(2, "0");
    var dateTime =
      year + "" + month + "" + day + "" + hour + "" + minute + "" + second;
    // console.log(dateTime);
    //var date = year+""+month+""+day;
    var currentDateTime = dateTime;

    this.dynamicArray.push({
      Id: currentDateTime,
      Name: "",
      Designation: "",
      FocusArea: "",
      BioRemarks: "",
      VisitedLab: "nil",
      PhotoName: "",
    });
    this.delagateNameBool = false;
    this.delegateDesignationBool = false;
    this.delegateFocusAreaBool = false;
    this.enableDetailsBtn = true;
    this.enableAddDelegate = true;
    console.log("New row added successfully", "New Row");
  }
  deleteRow(index) {
    this.dynamicArray.splice(index, 1);
    console.log(this.dynamicArray.length);
    if (this.dynamicArray.length == 0) {
      this.enableAddDelegate = false;
      this.delagateNameBool = false;
      this.delegateDesignationBool = false;
      this.delegateFocusAreaBool = false;
      console.log(
        this.delagateNameBool +
          " " +
          this.delegateDesignationBool +
          " " +
          this.delegateFocusAreaBool
      );
      this.enableDetailsBtn = true;
    } else {
      if (
        (this.delagateNameBool == true &&
          this.delegateDesignationBool == true &&
          this.delegateFocusAreaBool == true) ||
        this.dynamicArray.length > 0
      ) {
        this.enableDetailsBtn = false;
      }
    }
  }
  getValues() {
    console.log(this.dynamicArray);
  }

  displayedColumns: string[] = COLUMNS_SCHEMA.map((col) => col.key);
  dataSource = this.dynamicArray;
  columnsSchema: any = COLUMNS_SCHEMA;

  formData: any = {};
  stepData: any = {};
  visitDate;
  visitDay;

  slide1Data: any = {};
  slide2Data: any = {};
  slide3Data: any = {};
  valid: any = {};
  options: any = { year: "numeric", month: "long", day: "numeric" };

  hideMyModal() {
    // this.formModal.hide();
    document.getElementById("lab-visit-modal").style.display = "none";
    this.close.emit();
    this.stepper.reset();
    this.summaryStep = true;
    this.bookingSuccess = false;
    // document.getElementById("nav-bar").style.paddingTop = "25px"
    console.log(this.summaryStep + " " + this.bookingSuccess);
    this.dynamicArray = [];
    this.enableDetailsBtn = true;
    this.enableTimeslots = true;
    // document.getElementById("timeslot-select").style.opacity = "50%";
  }

  enableSubmitBtn(event) {
    console.log("checkbox clicked!");
    if (event.target.checked == true) this.enableSubmit = false;
    else this.enableSubmit = true;
  }

  hideBookingSuccess() {
    // this.formModal.hide();
    document.getElementById("lab-visit-modal").style.display = "none";
    this.summaryStep = true;
    this.bookingSuccess = false;
    this.displayTitle = true;
    this.stepData = {};
    this.dynamicArray = [];
    this.visitDate = "";
    this.close.emit();
    console.log(this.stepData);
    console.log(this.summaryStep + " " + this.bookingSuccess);
    this.enableSubmit = true;
    this.enableDetailsBtn = true;
    this.enableTimeslots = true;
    // document.getElementById("timeslot-select").style.opacity = "50%";
  }

  // getSlide1Data() {
  //   console.log("Previous Step data",this.stepData);
  //   const labLocation = this.firstFormGroup.get("radioValidation").value;
  //   var formatdate = new Date(this.firstFormGroup.get("dateValidation").value).toLocaleDateString("en-us", this.options)
  //   const visitDate = formatdate;
  //   const timeSlot = this.firstFormGroup.get("timeValidation").value

  //   if(labLocation.length > 0 && visitDate.length > 0 && timeSlot.length > 0) {
  //     this.slide1Data = {
  //       labLocation : labLocation,
  //       visitDate : formatdate,
  //       timeSlot : timeSlot
  //     }
  //     console.log(this.slide1Data);
  //   }

  //   var currentmonth: any = currentDate.getMonth() + 1;
  //   if (currentmonth < 10) {
  //     currentmonth = '0' + currentmonth;
  //   }
  //   var currentyear = currentDate.getFullYear();
  //   currentDate = currentyear + "-" + currentmonth + "-" + toDate;
  //   this.minDate = currentDate;
  //   console.log("Current Date", this.minDate);
  // }

  // onSubmit() {

  //   console.log(this.bookalabvalidation.valid);
  //   var formatdate = new Date(this.bookalabvalidation.get("date").value).toLocaleDateString("en-us", this.options)

  //   this.formData = {
  //     formName: "BookaLab",
  //     userAccount: this.bookalabvalidation.get("accountlab").value,
  //     usercp: this.bookalabvalidation.get("cp").value,
  //     userdesc: this.bookalabvalidation.get("desc").value,
  //     userdate: formatdate,
  //     userStakeholder: this.bookalabvalidation.get("stakeholder").value,
  //     username: sessionStorage.getItem('userName'),
  //     userEmployeeId: sessionStorage.getItem('userId')
  //   }

  //   if (this.bookalabvalidation.valid) {

  //     this.http.post("https://hhxa8qsu2m.execute-api.us-east-1.amazonaws.com/dev", this.formData).subscribe((response) => {
  //       this.result = response;
  //       this.responseId = JSON.stringify(this.result.MessageId);
  //       if (!this.responseId) {
  //         this.alerterror = true;
  //         this.closeAlertSuccess();
  //         this.message = 'Something went wrong,Please try again later';

  //       }
  //       else {
  //         this.alertsuccess = true;
  //         this.message = 'Form Submitted Successfully! We will contact you shortly.';
  //         this.closeAlertError();

  //       }

  //     })

  //     setTimeout(() => {
  //       this.close.emit();
  //     }, 4000);

  //   } else {
  //     console.log('form not valid');
  //   }
  // }
  // closeAlertSuccess() {
  //   this.alertsuccess = false;

  getSlide2Data() {
    console.log("Previous Step data", this.stepData);

    const accountName = this.secondFormGroup.get("accountValidation").value;
    const geography = this.secondFormGroup.get("geographyValidation").value;
    const projectCode = this.secondFormGroup.get("edpName").value;
    const yearsWithCognizant =
      this.secondFormGroup.get("yearsWithCognizant").value;
    const purpose = this.secondFormGroup.get("purpose").value;
    const overallAgenda = this.secondFormGroup.get("overallAgenda").value;
    const interestArea = this.secondFormGroup.get("interestArea").value;

    if (
      accountName.length > 0 &&
      geography.length > 0 &&
      projectCode.length > 0
    ) {
      this.slide2Data = {
        accountName: accountName,
        geography: geography,
        projectCode: projectCode,
        yearsWithCognizant: yearsWithCognizant,
        purpose: purpose,
        overallAgenda: overallAgenda,
        interestArea: interestArea,
      };
      console.log(this.slide2Data);
    } else console.log("Data empty");
  }

  weekendsDatesFilter = (d: Date): boolean => {
    const day = (d || new Date()).getDay();
    const dateString = d.toISOString().substring(0, 10);
    /* Prevent Saturday and Sunday for select. */
    return day !== 0 && day !== 6 && !this.blockedDates.includes(dateString);
  };

  modifyStartDate(newValue: any) {
    console.log(this.selectedTime);
    var tempEndTime = [];
    var tempTime = [
      "10:00 AM",
      "10:30 AM",
      "11:00 AM",
      "11:30 AM",
      "12:00 PM",
      "12:30 PM",
      "1:00 PM",
      "1:30 PM",
      "2:00 PM",
      "2:30 PM",
      "3:00 PM",
      "3:30 PM",
      "4:00 PM",
    ];
    // for (var i = 0; i < tempTime.length; i++) {
    //   if (this.selectedTime == tempTime[i]) {
    //     for (var j = i + 2; j < tempTime.length; j++) {
    //       tempEndTime.push(tempTime[j]);
    //     }
    //     break;
    //   }
    // }
    for (var i = 0; i < tempTime.length; i++) {
      if (this.selectedTime == tempTime[i]) {
        // for (var j = i + 2; j < tempTime.length; j++) {
        tempEndTime.push(tempTime[i + 2]);
        const tempEnd = this.firstFormGroup.get("endTimeValidation");
        tempEnd.setValue(tempTime[i + 2]);
        console.log(tempEnd.value);
        this.newEndTime = tempTime[i + 2];
        break;
        // }
      }
    }
    this.endTimeSlots = tempEndTime;
    console.log(tempEndTime);
    console.log(this.endTimeSlots);
  }

  modifyDate(newValue: any) {
    this.enableTimeslots = true;
    // document.getElementById("timeslot-select").style.opacity = "50%";
    var formatdate = new Date(newValue).toLocaleDateString(
      "en-us",
      this.options
    );
    this.stepData.dateValidation = formatdate;
    console.log("From ModifyDate function", formatdate);
    // this.fetchVisitDetails(formatdate);

    const VISIT_DATE = new Date(formatdate);
    let date = VISIT_DATE.getDay();

    const daysArr = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday"];
    this.visitDay = daysArr[date - 1];
    console.log(this.visitDay);
    console.log("From modify data fn", this.stepData.dateValidation);
  }
  // fetchVisitDetails(formatdate: string) {
  //   this.http
  //     .post(
  //       "https://hhxa8qsu2m.execute-api.us-east-1.amazonaws.com/dev/fetchvisitdetails",
  //       JSON.stringify(formatdate)
  //     )
  //     .subscribe((response) => {
  //       if (response) {
  //         this.enableTimeslots = false;
  //         var result = Object.keys(response).length;
  //         console.log(result);
  //         if(result == 0){
  //           this.displayErrorMsg = true;
  //         }
  //         else
  //           this.displayErrorMsg = false;
  //         document.getElementById("timeslot-select").style.opacity = "100%";
  //         console.log(response);
  //         // this.timeslots = response;
  //         console.log("Final TimeSlots", this.timeslots);
  //       }
  //     });
  // }

  //Incase of Revert paste the below code class component

  // data: any = {};
  // name = '';
  // email = '';
  // formData: any = {};
  // alertsuccess: boolean = false;
  // alerterror: boolean = false;
  // options: any = { year: 'numeric', month: 'long', day: 'numeric' };
  // result: any = {};
  // responseId = '';
  // message = '';
  // minDate: any = '';

  //   disablePastDate() {
  //     var : any = new Date();
  //     var toDate: any = currentDate.getDate();
  //     if (toDate < 10) {
  //       toDate = '0' + toDate;
  //     }
  //     var currentmonth: any = currentDate.getMonth() + 1;
  //     if (currentmonth < 10) {
  //       currentmonth = '0' + currentmonth;
  //     }
  //     var currentyear = currentDate.getFullYear();
  //     currentDate = currentyear + "-" + currentmonth + "-" + toDate;
  //     this.minDate = currentDate;
  //     console.log("Current Date", this.minDate);

  //   }
  //   onSubmit() {

  //     console.log(this.bookalabvalidation.valid);
  //     var formatdate = new Date(this.bookalabvalidation.get("date").value).toLocaleDateString("en-us", this.options)

  //     this.formData = {
  //       formName: "BookaLab",
  //       userAccount: this.bookalabvalidation.get("accountlab").value,
  //       usercp: this.bookalabvalidation.get("cp").value,
  //       userdesc: this.bookalabvalidation.get("desc").value,
  //       userdate: formatdate,
  //       userStakeholder: this.bookalabvalidation.get("stakeholder").value,
  //       username: sessionStorage.getItem('userName'),
  //       userEmployeeId: sessionStorage.getItem('userId')
  //     }

  //     if (this.bookalabvalidation.valid) {

  //       this.http.post("https://hhxa8qsu2m.execute-api.us-east-1.amazonaws.com/dev", this.formData).subscribe((response) => {
  //         this.result = response;
  //         this.responseId = JSON.stringify(this.result.MessageId);
  //         if (!this.responseId) {
  //           this.alerterror = true;
  //           this.closeAlertSuccess();
  //           this.message = 'Something went wrong,Please try again later';

  //         }
  //         else {
  //           this.alertsuccess = true;
  //           this.message = 'Form Submitted Successfully! We will contact you shortly.';
  //           this.closeAlertError();

  //         }

  //       })

  //       setTimeout(() => {
  //         this.close.emit();
  //       }, 4000);

  //     } else {
  //       console.log('form not valid');
  //     }
  //   }
  //   closeAlertSuccess() {
  //     this.alertsuccess = false;

  //   }
  //   closeAlertError() {
  //     this.alerterror = false;
  //   }
}
