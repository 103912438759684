import { Subject, BehaviorSubject } from "rxjs";
import {
  EventEmitter,
  Injectable,
  OnDestroy,
  Output,
  ViewChild,
} from "@angular/core";
import { MatStepper } from "@angular/material";

@Injectable({
  providedIn: "root",
})
export class SharedServiceService {
  private _moveTo = new Subject<{ i: number; title: string }>();
  videoDoamin =
    window.location.hostname === "localhost"
      ? "https://bfslabdev.cognizant.com/videos/"
      : `https://${window.location.hostname}/videos/`;
  public openSidePanel = new Subject();
  public openSidePanel$ = this.openSidePanel.asObservable();
  public headerLogo = new Subject();
  public headerLogo$ = this.headerLogo.asObservable();
  public frankId = new BehaviorSubject("");
  public frankId$ = this.frankId.asObservable();
  private userId: string;
  private recordId: string;
  public isOpen = false;

  constructor() {}
  ngOnDestroy(): void {
    throw new Error("Method not implemented.");
  }
  getDomain() {
    return this.videoDoamin;
  }
  moveTo(i: number, title: string) {
    this._moveTo.next({ i, title });
  }

  get moveTo$() {
    return this._moveTo.asObservable();
  }

  setUserId(id) {
    this.userId = id;
  }
  getUserId() {
    return this.userId;
  }

  setRecordId(id) {
    this.recordId = id;
  }
  getRecordId() {
    return this.recordId;
  }
}
