import { DataServiceService } from "./../services/data-service.service";
import { ActivatedRoute, Router } from "@angular/router";
import { SharedServiceService } from "./../services/shared-service.service";
import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { ParentComponent } from "../parent/parent.component";
import { AuthService } from "../services/auth/auth.service";

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"],
})
export class HeaderComponent extends ParentComponent implements OnInit {
  public src = "cts-newlogo";
  public _headerTheme: string;
  public zindex;
  public _transform = "0";
  public recordId;
  public h;
  public w;
  public headerIcon = false;

  @Output() open = new EventEmitter();
  @Output() move = new EventEmitter<any>();
  @Output() modalOpen = new EventEmitter();
  constructor(
    private sharedService: SharedServiceService,
    public _http: DataServiceService,
    private route: Router,
    private authService: AuthService
  ) {
    super(_http);
  }
  @Input()
  set headerTheme(name: string) {
    setTimeout(() => {
      this._headerTheme = name;
      if (name === "dark" || name === "semi_light") {
        this.src = "Header logo.png";
      }
      if (name === "light") {
        this.src = "Header logo.png";
      }
      // this.src = (name === 'dark' ? 'logo_darkBG.png' : (name === 'semi_light' ? 'logo_lightBG.png' : 'logo_darkBG.png'));
    }, 0);
  }

  @Input() set isOpen(val: boolean) {
    if (val) {
      this.zindex = -1;
    } else {
      this.zindex = 100;
    }
  }

  @Input() set transform(val: any) {
    this._transform = `translate3d(0px,${val}px,0px)`;
  }
  ngOnInit() {
    this.h = window.screen.height;
    this.w = window.screen.width;
    this.sharedService.headerLogo$.subscribe((ev: boolean) => {
      this.headerIcon = ev;
    });
  }
  navigateTo(index: number, title: string) {
    this.sharedService.moveTo(index, title);
  }

  logout() {
    this.authService.logout();
  }
  moveTo(i, title?) {
    this.move.emit({ index: 1, title });
  }

  openMenuIcon() {
    this.sharedService.openSidePanel.next(true);
    this.headerIcon = true;
    this.recordId = this.sharedService.getRecordId();
    this.saveHistory(`open_menubar`, 0, this.recordId);
  }

  openMenu() {
    this.sharedService.openSidePanel.next(true);
    this.recordId = this.sharedService.getRecordId();
    this.saveHistory(`open_menubar`, 0, this.recordId);
  }
  getTransform() {
    return `translate3d(0px,${this._transform}px,0px)`;
  }
}
