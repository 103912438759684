import { DataServiceService } from "./../services/data-service.service";
import { SharedServiceService } from "./../services/shared-service.service";
import { Component, OnInit, Input, EventEmitter, Output } from "@angular/core";
import { ParentComponent } from "../parent/parent.component";

@Component({
  selector: "app-navigation",
  templateUrl: "./navigation.component.html",
  styleUrls: ["./navigation.component.scss"],
})
export class NavigationComponent extends ParentComponent implements OnInit {
  public class = false;
  public selectedIndex;
  public recordId;
  public navigationTitle;
  @Input() set selectedMenu(val: any) {
    this.selectedIndex = val;
  }
  @Output() move = new EventEmitter<any>();
  @Output() movetosection = new EventEmitter<number>();
  constructor(
    private sharedService: SharedServiceService,
    public _http: DataServiceService
  ) {
    super(_http);
  }

  ngOnInit() {
    this.sharedService.openSidePanel$.subscribe((ev: boolean) => {
      this.class = ev;
    });
    this.sharedService.moveTo$.subscribe((data) => {
      this.moveTO(data.i, data.title);
    });
  }
  closeMenu() {
    this.class = false;
    this.sharedService.headerLogo.next(false);
    // this.recordId = this.sharedService.getRecordId();
    // this.saveHistory('close_menu', 1, this.recordId);
  }

  moveTO(i, title?) {
    this.selectedIndex = i;
    this.move.emit({ index: i, title });
    this.closeMenu();
    this.navigationTitle = title;
    if (this.navigationTitle === "nav_our_genAI_catalog") {
      this._http.sendMessage("yes");
      console.log("inside if");
    } else if (this.navigationTitle === "nav_our_catalog") {
      this._http.sendMessage("no");
      console.log("inside if no");
    }
  }

  moveToSection(i, title?) {
    this.selectedIndex = i;
    this.movetosection.emit(i);
    this.closeMenu();
  }
}
